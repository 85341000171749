import ApiService from '@/shared/services/api.service';

export class IssuedDocumentService {
    
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/issued-documents', params)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static getDocumentIssuerList() {
        const params = { 
            params: { 
                group: ['issuer_user_full_name', 'issuer_user_id'],
                sort: 'issuer_user_full_name',
                envelope: false,
                limit: 100,
            },
        };
        return new Promise((resolve, reject) => {
            ApiService.query('/issued-documents', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getDocumentTypeList() {
        const params = {
            params: {
                group: 'document_name',
                sort: 'document_name',
                envelope: false,
                limit: 100,
            },
        };
        return new Promise((resolve, reject) => {
            ApiService.query('/issued-documents', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getYears() {
        const params = {
            params: {
                group: 'issued_year',
                sort: 'issued_year',
                envelope: false,
                limit: 100,
            },
        };
        return new Promise((resolve, reject) => {
            ApiService.query('/issued-documents', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}
