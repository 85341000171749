import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import DateField from '@/shared/fields/date-field';
import ComputedField from '@/shared/fields/computed-field';
import { IssuedDocumentIssuerField } from '@/modules/issued-document/issued-document-issuer-field';
import { IssuedDocumentTypeField } from '@/modules/issued-document/issued-document-type-field';
import { IssuedYearField } from '@/modules/issued-document/issued-year-field';

const label = (name) => i18n.t(`ISSUED_DOCUMENT.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`ISSUED_DOCUMENT.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    issuer_user_id: IssuedDocumentIssuerField.relationToOne('issuer_user_id', label('issuer_user_full_name'), {}, {
        placeholder: placeholder('issuer_user_full_name'),
    }, [], { asyncData: true }),
    issuer_user_full_name: new StringField('issuer_user_full_name', label('issuer_user_full_name')),
    document_series: new IdField('document_series', label('document_series'), {}, {
        placeholder: placeholder('document_series'),
        filter_label: label('document_series'),
        primary: false,
    }),
    document_name: IssuedDocumentTypeField.relationToOne('document_name', label('document_name'), {}, {
        placeholder: placeholder('document_name'),
    }, [], { asyncData: true }),
    document_name_text: new StringField('document_name', label('document_name')),
    issued_date: new DateField('issued_date', label('issued_date')),
    file_id: new IdField('file_id'),
    beneficiary_full_names: new ComputedField('beneficiary_full_names', label('beneficiary_user'), 
    ({beneficiary_full_names}) => {
        if (!beneficiary_full_names?.length) return '';
        return beneficiary_full_names.join(', ');
    }, {}, {
        placeholder: placeholder('beneficiary_user'),
        filter_label: label('beneficiary_user'),
        primary: false,
    }),
    issued_year: IssuedYearField.relationToOne('issued_year', label('issued_year'), {}, {
        placeholder: placeholder('issued_year'),
    }, [], { asyncData: true }),
};

export class IssuedDocumentModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
